<template>
    <div class="hot_recommend">
        <header>
            <div class="banner_img_box_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG1477.jpeg" alt="">
            </div>
        </header>
        <section>
            <div class="hot_recommend_box">热门活动推荐</div>
            <div class="card_img_box_" @click="cardLinksSkip('1')">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG1475.png" alt="">
            </div>
            <div class="card_img_box_" @click="cardLinksSkip('2')">
                <img :src="this.$route.params.id == '1'? 'https://cdn.zhiyileiju.cn/WechatIMG1476.png' : 'https://cdn.zhiyileiju.cn/WechatIMG1478.png'" alt="">
            </div>
            <div class="card_img_box_" @click="cardLinksSkip('3')">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG1479.png" alt="">
            </div>
        </section>
        <footer></footer>
    </div>
</template>

<script>
export default {
    data () {
        return{
            urls_:'https://jx2.gyqingqing.com'
        }
    },
    created() {
        // console.log('id',this.$route.params.id)
    },
    methods:{
        cardLinksSkip(type) { //卡片链接跳转
            switch (type) {
                case '1':
                    if ( this.$route.params.id == '1' ) {//配对公众号
                        window.location.href=`${this.urls_}/wechatceyun?fromk=pdgzhys` //流年
                    }else{//婚姻公众号
                        window.location.href=`${this.urls_}/wechatceyun?fromk=hygzhys` //流年
                    }   
                    break;
                case '2':
                    if ( this.$route.params.id == '1' ) {//配对公众号
                        window.location.href=`${this.urls_}/wechatmerry?fromk=pdgzhhy` //婚姻
                    }else{//婚姻公众号
                        window.location.href=`${this.urls_}/wechatmatch?fromk=hygzhpd` //配对
                    }
                    break;
                case '3':
                    if ( this.$route.params.id == '1' ) {//配对公众号
                        window.location.href=`${this.urls_}/wechattestname?fromk=pdgzhcm` //测名
                    }else{//婚姻公众号
                        window.location.href=`${this.urls_}/wechattestname?fromk=hygzhcm` // 测名
                    }
                    break;
            
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .hot_recommend{
        header{
            .banner_img_box_{
                width: 7.5rem;
                height: 3.26rem;
                img{
                    height: 100%;
                }
            }
        }
        section{
            .hot_recommend_box{
                height: .78rem;
                line-height: .78rem;
                text-align: center;
                background: rgba(208, 54, 22, .2);
                font-size: .36rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #C91C18;
                margin-top: .2rem;
            }
            .card_img_box_{
                width: 7.5rem;
                height: 4.1rem;
                margin-top: .4rem;
            }
        }
        footer{
            width: 7.5rem;
            height: .86rem;
        }
    }
</style>